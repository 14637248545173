import { combineReducers } from '@reduxjs/toolkit'

import UserReducer from './reducers/user'
import BucketReducer from './reducers/buckets'
import SystemFlagsReducer from './reducers/systemFlags'
import NotificationsData from './reducers/notifications'
import Sockets from './reducers/sockets'
import DefinedHeights from './reducers/heights'

const rootReducer = combineReducers({
  User: UserReducer,
  Buckets: BucketReducer,
  SystemFlags: SystemFlagsReducer,
  NotificationsData,
  Sockets,
  DefinedHeights
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
