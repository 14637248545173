// import './wdyr'
import React from 'react'
import { Provider } from 'react-redux'
import { Switch, BrowserRouter } from 'react-router-dom'
import axios from 'axios'
// import { ReactQueryDevtools } from 'react-query/devtools'
// import { QueryClient, QueryClientProvider } from 'react-query'
import store from './redux/store'
import BootRoutes from './BootRoutes'

// Create a client
// const queryJSClient = new QueryClient()

axios.defaults.withCredentials = true

const App: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        {/* <QueryClientProvider client={queryJSClient}> */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <BootRoutes />
        {/* </QueryClientProvider> */}
      </Switch>
    </BrowserRouter>
  </Provider>
)

export default App
