import { createSlice } from '@reduxjs/toolkit'

export interface SystemFlags {
  isBucketsFetched: boolean
  isShowingNavArea: boolean
  isShadowBucketsFetched: boolean
}
const isShowingNavArea = localStorage.getItem('bucket-nav-area')

const initialState: SystemFlags = {
  isBucketsFetched: false,
  isShowingNavArea: isShowingNavArea !== 'false',
  isShadowBucketsFetched: false
}

const SystemFlagsSlice = createSlice({
  name: 'SystemFlags',
  initialState,
  reducers: {
    /**
     * Sets the redux flag that buckets have fetched
     * It is being used when we need to detect if the buckets have fetched or not
     */
    bucketsFetched(state) {
      state.isBucketsFetched = true
    },
    /**
     * Sets the redux flag that shadow buckets have fetched
     * It is being used when we need to detect if the shadow buckets have fetched or not
     */
    shadowBucketsFetched(state) {
      state.isShadowBucketsFetched = true
    },
    /**
     * It will toggle the nav area for us. We use it so we can hide nested nav area too :D
     */
    toggleNavArea(state) {
      localStorage.setItem('bucket-nav-area', `${!state.isShowingNavArea}`)
      return {
        ...state,
        isShowingNavArea: !state.isShowingNavArea
      }
    }
  }
})

export const { bucketsFetched, toggleNavArea, shadowBucketsFetched } = SystemFlagsSlice.actions
export default SystemFlagsSlice.reducer
