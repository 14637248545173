import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Bucket as IBucket,
  BucketChartCategory,
  BucketGroup,
  Collaborator,
  PipelineInterface,
  Tag
} from '../../../../../../interfaces/NewBucket'
import { ShadowBucket } from '../../../../../../interfaces/NewShadowBucket'

export interface Buckets {
  [key: string]: IBucket | ShadowBucket
}

interface FetchBucket {
  Buckets: Buckets
}

const initialState: Buckets = {}

const BucketSlice = createSlice({
  name: 'Buckets',
  initialState,
  reducers: {
    fetchBuckets: (state, action: PayloadAction<FetchBucket>) => {
      return {
        ...state,
        ...action.payload.Buckets
      }
    },
    updateBucket: (state, action: PayloadAction<{ Bucket: IBucket | ShadowBucket }>) => {
      if ((action.payload.Bucket as ShadowBucket).shadowBucketACLs) {
        state[action.payload.Bucket._id] = { ...state[action.payload.Bucket._id], ...action.payload.Bucket }
      } else {
        state[action.payload.Bucket.BrandingID] = { ...state[action.payload.Bucket.BrandingID], ...action.payload.Bucket }
      }
    },
    newBucket: (state, action: PayloadAction<{ Bucket: IBucket }>) => {
      return {
        ...state,
        [action.payload.Bucket.BrandingID]: action.payload.Bucket
      }
    },
    updateBucketTitle: (state, action: PayloadAction<updateTitle>) => {
      state[action.payload.BrandingID].title = action.payload.title
      return state
    },
    updatedBucketTags: (state, action: PayloadAction<updateTags>) => {
      state[action.payload.BrandingID].tags = action.payload.Tags
      return state
    },
    collaboratorAdded: (state, action: PayloadAction<CollaboratorAdded>) => {
      state[action.payload.BucketID].Collaborators = [...state[action.payload.BucketID].Collaborators, action.payload.collaborator]
      return state
    },
    collaboratorDeleted: (state, action: PayloadAction<CollaboratorDeleted>) => {
      const newCollabors: Collaborator[] = [...state[action.payload.BucketID].Collaborators]
      newCollabors.splice(
        newCollabors.findIndex((g) => g.id === action.payload.collaboratorID),
        1
      )
      state[action.payload.BucketID].Collaborators = newCollabors
      return state
    },
    groupAdded: (state, action: PayloadAction<GroupAdded>) => {
      state[action.payload.BucketID].groups = [...state[action.payload.BucketID].groups, action.payload.group]
      return state
    },
    categoryAdded: (state, action: PayloadAction<CategoryAdded>) => {
      state[action.payload.BucketID].categories = [...state[action.payload.BucketID].categories, action.payload.category]
      return state
    },
    tagAdded: (state, action: PayloadAction<TagAdded>) => {
      state[action.payload.BucketID].tags = [...state[action.payload.BucketID].tags, action.payload.tag]
      return state
    },
    groupDeleted: (state, action: PayloadAction<GroupDeleted>) => {
      const newGroups: BucketGroup[] = [...state[action.payload.BucketID].groups]
      newGroups.splice(
        newGroups.findIndex((g) => g.id === action.payload.groupID),
        1
      )
      state[action.payload.BucketID].groups = newGroups
      return state
    },
    categoryDeleted: (state, action: PayloadAction<CategoryDeleted>) => {
      const newCategories: BucketChartCategory[] = [...state[action.payload.BucketID].categories]
      newCategories.splice(
        newCategories.findIndex((g) => g.id === action.payload.categoryID),
        1
      )
      state[action.payload.BucketID].categories = newCategories
      return state
    },
    tagDeleted: (state, action: PayloadAction<TagDeleted>) => {
      const newTags: Tag[] = [...state[action.payload.BucketID].tags]
      newTags.splice(
        newTags.findIndex((t) => t.id === action.payload.tagID),
        1
      )
      state[action.payload.BucketID].tags = newTags
      return state
    },
    pipelineDeleted: (state, action: PayloadAction<PipelineDeleted>) => {
      const newPipeline: PipelineInterface[] = [...state[action.payload.BucketID].pipeline]
      newPipeline.splice(
        newPipeline.findIndex((p) => p.id === action.payload.pipelineID),
        1
      )
      state[action.payload.BucketID].pipeline = newPipeline
      return state
    },
    pipelineAdded: (state, action: PayloadAction<PipelineAdded>) => {
      state[action.payload.BucketID].pipeline = [...state[action.payload.BucketID].pipeline, action.payload.pipeline]
      return state
    },
    greetingAdded: (state, action: PayloadAction<GreetingAddedOrRemoved>) => {
      state[action.payload.BucketID].defaultPostSequence = state[action.payload.BucketID].defaultPostSequence
        ? [...(state[action.payload.BucketID].defaultPostSequence as any[]), action.payload.greetingid]
        : [action.payload.greetingid]
      return state
    },
    greetingRemoved: (state, action: PayloadAction<GreetingAddedOrRemoved>) => {
      state[action.payload.BucketID].defaultPostSequence = state[action.payload.BucketID].defaultPostSequence
        ? state[action.payload.BucketID].defaultPostSequence?.filter((p) => p !== action.payload.greetingid)
        : []
      return state
    }
  }
})

export const {
  updateBucket,
  fetchBuckets,
  newBucket,
  updateBucketTitle,
  updatedBucketTags,
  collaboratorAdded,
  groupAdded,
  groupDeleted,
  categoryAdded,
  categoryDeleted,
  collaboratorDeleted,
  tagDeleted,
  tagAdded,
  pipelineAdded,
  pipelineDeleted,
  greetingAdded,
  greetingRemoved
} = BucketSlice.actions
export default BucketSlice.reducer

interface CollaboratorAdded {
  BucketID: string
  collaborator: Collaborator
}
interface CollaboratorDeleted {
  BucketID: string
  collaboratorID: string
}
interface GroupAdded {
  BucketID: string
  group: BucketGroup
}
interface CategoryAdded {
  BucketID: string
  category: BucketChartCategory
}
interface TagAdded {
  BucketID: string
  tag: Tag
}
interface GroupDeleted {
  BucketID: string
  groupID: string
}
interface CategoryDeleted {
  BucketID: string
  categoryID: string
}
interface TagDeleted {
  BucketID: string
  tagID: string
}
interface updateTitle {
  BrandingID: string
  title: string
}

interface PipelineDeleted {
  BucketID: string
  pipelineID: string
}
interface PipelineAdded {
  BucketID: string
  pipeline: PipelineInterface
}

interface updateTags {
  BrandingID: string
  Tags: Tag[]
}

interface GreetingAddedOrRemoved {
  BucketID: string
  greetingid: string
}
