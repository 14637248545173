import React, { Component, Suspense } from 'react'
import { Loader } from 'rsuite'
import CookieConsent from 'react-cookie-consent'
import { connect } from 'react-redux'
import Axios, { AxiosError, AxiosResponse } from 'axios'
import { CleanAppAccountForSelf as User } from '../../../interfaces/Accounts'

import { RootState } from './redux/combineReducers'
import APIConfig from './APIConfig'
import { userLogin } from './redux/reducers/user'
import LazyLoader from './globalComponents/LazyLoader'
import { identifyTracking } from './tracking'

// Routes
const OpenRoutes = React.lazy(() => LazyLoader(() => import('./openRoutes'), 5))
const RestrictedRoutes = React.lazy(() => LazyLoader(() => import('./restrictedRoutes'), 5))

interface State {
  isBooting: boolean
}

interface Props {
  User?: User
  userLoggedIn?: () => void
  userLogin?: (user: User) => void
}

class BootRoutes extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isBooting: true
    }
  }

  componentDidMount() {
    Axios.get(APIConfig.Boot)
      .then((res: AxiosResponse<any>) => {
        console.log('boot result:', res)

        if (!this.props.userLogin) return

        this.props.userLogin(res.data.User)
        identifyTracking(res.data.User._id, res.data.User.fullName, res.data.User.email)
        this.setState({
          isBooting: false
        })
      })
      .catch((err: AxiosError<any>) => {
        console.log('error:', err.response?.data)
        this.setState({
          isBooting: false
        })
      })
  }

  render() {
    return (
      <>
        {this.state.isBooting && (
          <div
            style={{
              height: window.innerHeight,
              flexDirection: 'column',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loader size="md" />
            <span style={{ marginTop: 30 }}>Checking Authentication</span>
          </div>
        )}
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <>
            {!this.state.isBooting && this.props.User && this.props.User._id && <RestrictedRoutes />}
            {!this.state.isBooting && this.props.User && !this.props.User._id && <OpenRoutes />}
          </>
        </Suspense>

        {!localStorage.getItem('cookieConsent') && (
          <CookieConsent
            onAccept={(decision) => {
              localStorage.setItem('cookieConsent', 'true')
            }}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  User: state.User
})

const mapDispatchToProps = {
  userLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(BootRoutes)
