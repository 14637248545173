import mixpanel from 'mixpanel-browser'
const IS_DEV = process.env.NODE_ENV === 'development'

export function identifyTracking(userID: string, fullName: string, email: string) {
  if (IS_DEV) return

  mixpanel.identify(userID)
  mixpanel.people.set({ name: fullName, email: email })
}

export function trackEvent(...args: any[]) {
  if (IS_DEV) return

  const args_safe = [args[0], args[1], args[2]] as const

  mixpanel.track(...args_safe)
}
