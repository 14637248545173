import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISocketsReduxInterface {
  /** Socket is connected or not */
  connected: boolean
  /** Which rooms socket have joined */
  rooms: string[]
}

const initialState: ISocketsReduxInterface = {
  connected: true,
  rooms: []
}

const SocketsSlice = createSlice({
  name: 'Sockets',
  initialState,
  reducers: {
    onSocketConnected: (state, action: PayloadAction) => {
      return {
        ...state,
        connected: true
      }
    },
    onSocketDisconnected: (state, action: PayloadAction) => {
      return {
        ...state,
        connected: false
      }
    }
  }
})

export const { onSocketConnected, onSocketDisconnected } = SocketsSlice.actions
export default SocketsSlice.reducer
