import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DefinedHeights {
  bucketNavArea: number | null
  headerArea: number | null
  bucketChartNavArea: number | null
  inputAreaTimeline: number | null
}

const initialState: DefinedHeights = {
  bucketNavArea: null,
  headerArea: null,
  bucketChartNavArea: null,
  inputAreaTimeline: null
}

const DefinedHeightsSlice = createSlice({
  name: 'DefinedHeights',
  initialState,
  reducers: {
    updateBucketNavArea(state, action: PayloadAction<{ height: number }>) {
      state.bucketNavArea = action.payload.height
    },
    updateHeaderArea(state, action: PayloadAction<{ height: number }>) {
      state.headerArea = action.payload.height
    },
    updateBucketChartNavArea(state, action: PayloadAction<{ height: number }>) {
      state.bucketChartNavArea = action.payload.height
    },
    updateinputAreaTimeline(state, action: PayloadAction<{ height: number }>) {
      state.inputAreaTimeline = action.payload.height
    }
  }
})

export const { updateBucketNavArea, updateHeaderArea, updateBucketChartNavArea, updateinputAreaTimeline } = DefinedHeightsSlice.actions
export default DefinedHeightsSlice.reducer
