import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CleanAppAccountForSelf as User } from '../../../../../../interfaces/Accounts'

interface UpdateFullName {
  fullName: string
}
interface personalInfo {
  fullName: string
  email: string
}

interface UpdateAvatarURL {
  avatarURL: string
}

interface UpdateFullNameAndMobile {
  fullName: string
  mobileNumber: string
}

interface SMSNotification {
  smsNotification: boolean
}
interface EMAILNotification {
  emailNotification: boolean
}

const initialState: User = {}

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    updateFullName: (state, action: PayloadAction<UpdateFullName>) => {
      const { fullName } = action.payload
      state.fullName = fullName
    },
    updateSmsNotification: (state, action: PayloadAction<SMSNotification>) => {
      const { smsNotification } = action.payload
      state.flags!.notifications.isSMSNotificationEnabled = smsNotification
    },
    updateEmailNotification: (state, action: PayloadAction<EMAILNotification>) => {
      const { emailNotification } = action.payload
      state.flags!.notifications.isEmailNotificationEnabled = emailNotification
    },
    updatePersonalInfo: (state, action: PayloadAction<personalInfo>) => {
      const { fullName, email } = action.payload
      state.fullName = fullName
      state.email = email
      return state
    },
    updateAvatarUrl: (state, action: PayloadAction<UpdateAvatarURL>) => {
      const { avatarURL } = action.payload
      state.avatarURL = avatarURL
    },
    userLogin: (state, action: PayloadAction<User>) => {
      return action.payload
    },
    userForTwoFactor: (state, action: PayloadAction<UpdateFullNameAndMobile>) => {
      const { fullName, mobileNumber } = action.payload
      state.fullName = fullName
      state.mobileNumber = mobileNumber
    }
  }
})

export const {
  updateFullName,
  userLogin,
  updateSmsNotification,
  updateEmailNotification,
  userForTwoFactor,
  updateAvatarUrl,
  updatePersonalInfo
} = userSlice.actions
export default userSlice.reducer
