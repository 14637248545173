import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import rootReducer, { RootState } from './combineReducers'

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware<RootState>()]
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./combineReducers', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./combineReducers').default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch

export default store
