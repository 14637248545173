// import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import mixpanel from 'mixpanel-browser'

import 'rsuite/lib/styles/index.less'
import 'pappu-editor/dist/style.css'
import 'react-phone-input-2/lib/style.css'

const initPlausible = () => {
  const { head } = document
  const scripts = head.getElementsByTagName('script')
  const plausibleScript = Array.from(scripts).filter((script) => script.src === 'https://plausible.io/js/plausible.js')
  if (plausibleScript.length === 0) {
    const script = document.createElement('script')
    script.setAttribute('data-domain', 'account.advanced-wellness.org')
    script.src = 'https://plausible.io/js/plausible.js'
    document.head.appendChild(script)
  }
}

const initSentry = () => {
  Sentry.init({
    dsn: 'https://143525561a5349f0ada042806f182c5c@o265666.ingest.sentry.io/5500039',
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.3
  })
}

const initMixPanel = () => {
  mixpanel.init('c2cd8e117b4218abf4d50ea7bcf3b0fd', { debug: true })
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // initPlausible()
  // Sentry.init({dsn: "https://e1b796d0f086489b995024b52a4d704a@sentry.io/2054292"});
} else {
  initPlausible()
  initSentry()
  initMixPanel()
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
