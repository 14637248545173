let server
let local = false
let PORTAL_LINK
let GOOGLE_MAPS_API_KEY
let GO_LINK
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  server = `http://${process.env.REACT_APP_SERVER}:8080`
  local = true
  PORTAL_LINK = `http://${process.env.REACT_APP_SERVER}:3001`
  GO_LINK = `http://${process.env.REACT_APP_SERVER}:8081`
  GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_DEV_API_KEY
} else {
  // production code
  server = 'https://apiv2.advanced-wellness.org'
  // server = 'https://advanced-wellness-rozakis.uc.r.appspot.com'
  GO_LINK = `https://go.aw.care`
  PORTAL_LINK = 'https://portal.advanced-wellness.org'
  GOOGLE_MAPS_API_KEY = 'AIzaSyAaa2siVJVWptSXYl_nrsGdzPb3si9ffR0'
}

const APIConfig = {
  PORTAL_LINK,
  GO_LINK,
  GOOGLE_MAPS_API_KEY,
  SocketIO: `${server}/account`,
  Boot: `${server}/account/boot`,
  Login: `${server}/account/login`,
  Logout: `${server}/account/logout`,
  RecoverEmail: `${server}/account/recover-password`,
  VerifyResetToken: `${server}/account/verify-reset-token`,
  // UpdatePassword: `${server}/doctor/update-password`,
  VerifyRecoverToken: `${server}/account/verify-recover-token`,
  RecoverAccount: `${server}/account/recover-account`,
  Register: `${server}/account/register`,
  Setup2Factor: `${server}/account/setup-2-factor-auth`,
  Setup2FactorVerifyOTP: `${server}/account/setup-2-factor-auth-verify-totp`,
  VerifyLoginOTP: `${server}/account/login-verifyotp`,
  ResendLoginOTP: `${server}/account/login-resendotp`,
  GetBuckets: `${server}/account/get-buckets`,
  CreateBucket: `${server}/account/create-bucket`,
  UpdateBucketTitle: `${server}/account/update-bucket-title`,
  DeleteBucket: `${server}/account/delete-bucket`,
  EditorUploads: `${server}/account/editor-uploads`,
  TimelineUploads: `${server}/account/timeline-uploads`,
  ChartTimelineUploads: `${server}/account/chart-timeline-uploads`,
  ProfilePictureUpload: `${server}/account/upload-profile-pic`,
  UpdatePersonalInfo: `${server}/account/update-personal-info`,
  UpdatePassword: `${server}/account/update-password`,
  logoutAllDevices: `${server}/account/logout-all-devices`,
  CloseAccount: `${server}/account/close-account`,
  bucketQuota: `${server}/account/bucket-quota`,
  getUnseenNotification: `${server}/account/unseen-notifications`,
  addCollaborator: `${server}/account/add-collaborator`,
  deleteCollaborator: `${server}/account/delete-collaborator`,
  deleteShadowCollaborator: `${server}/account/delete-shadow-bucket-collaborator`,
  updateCollaboratorACLs: `${server}/account/update-collaborator-acl`,
  getBucketLevelACLs: `${server}/variables/bucket-level-acls`,
  getGroupLevelACLs: `${server}/variables/group-level-acls`,
  createGroup: `${server}/account/create-group`,
  deleteGroup: `${server}/account/delete-group`,
  editGroup: `${server}/account/edit-group`,
  updateCollaboratorsInGroup: `${server}/account/update-group-collaborators`,
  updateCollaboratorACLsInBucket: `${server}/account/update-group-acl-for-collaborator`,
  createCategory: `${server}/account/create-category`,
  deleteCategory: `${server}/account/delete-category`,
  editCategory: `${server}/account/edit-category`,
  defaultCategory: `${server}/account/default-category`,
  deleteTag: `${server}/account/delete-tag`,
  createTag: `${server}/account/create-tag`,
  editTag: `${server}/account/edit-tag`,

  addShadowCollaborator: `${server}/account/add-shadow-collaborator`,
  updateShadowCollaboratorACLs: `${server}/account/update-shadow-collaborator-acl`,
  createShadowGroup: `${server}/account/create-shadow-group`,
  deleteShadowGroup: `${server}/account/delete-shadow-group`,
  editShadowGroup: `${server}/account/edit-shadow-group`,
  updateShadowCollaboratorsInGroup: `${server}/account/update-shadow-group-collaborators`,
  updateShadowCollaboratorACLsInBucket: `${server}/account/update-shadow-group-acl-for-collaborator`,
  createShadowCategory: `${server}/account/create-shadow-category`,
  deleteShadowCategory: `${server}/account/delete-shadow-category`,
  editShadowCategory: `${server}/account/edit-shadow-category`,
  defaultShadowCategory: `${server}/account/default-shadow-category`,
  deleteShadowTag: `${server}/account/delete-shadow-tag`,
  createShadowTag: `${server}/account/create-shadow-tag`,
  editShadowTag: `${server}/account/edit-shadow-tag`,

  getBucketShadows: `${server}/account/get-all-bucket-shadows`,
  createShadowBucket: `${server}/account/create-shadow`,
  getShadowsOfAccount: `${server}/account/get-shadows`,

  getBucketProducts: `${server}/account/get-all-bucket-products`,
  createBucketProduct: `${server}/account/create-bucket-product`,
  editBucketProduct: `${server}/account/edit-bucket-product`,

  getBucketProductGroups: `${server}/account/get-all-bucket-product-groups`,
  createBucketProductGroup: `${server}/account/create-bucket-product-group`,
  editBucketProductGroup: `${server}/account/edit-bucket-product-group`,

  addProductToGreeting: `${server}/account/add-product-to-greeting`,
  addProductGroupToGreeting: `${server}/account/add-product-group-to-greeting`,
  removeBuyButtonFromGreeting: `${server}/account/remove-buy-button-from-greeting`,

  getBucketGreetings: `${server}/account/get-bucket-greetings`,
  getShadowBucketGreetings: `${server}/account/get-shadow-bucket-greetings`,
  createGreeting: `${server}/account/create-greeting`,
  createShadowGreeting: `${server}/account/create-shadow-greeting`,
  editGreeting: `${server}/account/edit-greeting`,
  editShadowGreeting: `${server}/account/edit-shadow-greeting`,
  deleteGreeting: `${server}/account/delete-greeting`,
  deleteShadowGreeting: `${server}/account/delete-shadow-greeting`,
  saveGreetingContent: `${server}/account/save-greeting-content`,
  saveShadowGreetingContent: `${server}/account/save-shadow-greeting-content`,
  updateBucketGreetingSequence: `${server}/account/update-bucket-greeting-sequence`,
  updateShadowBucketGreetingSequence: `${server}/account/update-shadow-bucket-greeting-sequence`,
  addAttachmentsInBucketGreeting: `${server}/account/add-attachments-bucket-greetings`,
  addAttachmentsInShadowBucketGreeting: `${server}/account/add-attachments-shadow-bucket-greetings`,
  removeAttachemtInBucketGreeting: `${server}/account/remove-attachment-bucket-greetings`,
  removeAttachemtInShadowBucketGreeting: `${server}/account/remove-attachment-shadow-bucket-greetings`,
  addThreadsInBucketGreeting: `${server}/account/add-threads-bucket-greetings`,
  addThreadsInShadowBucketGreeting: `${server}/account/add-threads-shadow-bucket-greetings`,
  removeThreadInBucketGreeting: `${server}/account/remove-thread-bucket-greetings`,
  removeThreadInShadowBucketGreeting: `${server}/account/remove-thread-shadow-bucket-greetings`,

  getBucketThreads: `${server}/account/get-bucket-threads`,
  getShadowBucketThreads: `${server}/account/get-shadow-bucket-threads`,
  createBucketThread: `${server}/account/create-bucket-thread`,
  createShadowBucketThread: `${server}/account/create-shadow-bucket-thread`,
  deleteThread: `${server}/account/delete-bucket-thread`,
  deleteShadowThread: `${server}/account/delete-shadow-bucket-thread`,
  updateBucketThreadSettings: `${server}/account/update-bucket-thread-settings`,
  updateShadowBucketThreadSettings: `${server}/account/update-shadow-bucket-thread-settings`,

  deleteBucketPipeline: `${server}/account/delete-bucket-pipeline`,
  deleteShadowBucketPipeline: `${server}/account/delete-shadow-bucket-pipeline`,
  createBucketPipeline: `${server}/account/create-bucket-pipeline`,
  createShadowBucketPipeline: `${server}/account/create-shadow-bucket-pipeline`,
  editBucketPipelineItem: `${server}/account/edit-bucket-pipeline-item`,
  editShadowBucketPipelineItem: `${server}/account/edit-shadow-bucket-pipeline-item`,

  fetchBucketCharts: `${server}/account/fetch-bucket-charts`,
  fetchShadowBucketCharts: `${server}/account/fetch-shadow-bucket-charts`,

  getBucketChart: `${server}/account/get-bucket-chart`,
  getShadowBucketChart: `${server}/account/get-shadow-bucket-chart`,

  updateBucketChartTags: `${server}/account/update-bucket-chart-tags`,
  updateShadowBucketChartTags: `${server}/account/update-shadow-bucket-chart-tags`,

  updateBucketChartCategories: `${server}/account/update-bucket-chart-categories`,
  updateShadowBucketChartCategories: `${server}/account/update-shadow-bucket-chart-categories`,

  updateBucketChartGroups: `${server}/account/update-bucket-chart-groups`,
  updateShadowBucketChartGroups: `${server}/account/update-shadow-bucket-chart-groups`,

  updateBucketChartPatientProfile: `${server}/account/update-bucket-chart-patient-profile`,
  updateShadowBucketChartPatientProfile: `${server}/account/update-shadow-bucket-chart-patient-profile`,

  updateBucketChartAssignees: `${server}/account/update-bucket-chart-assignees`,
  updateShadowBucketChartAssignees: `${server}/account/update-shadow-bucket-chart-assignees`,

  fetchChartFeeds: `${server}/account/get-bucket-chart-feeds`,
  fetchShadowChartFeeds: `${server}/account/get-shadow-bucket-chart-feeds`,

  fetchSheetFeeds: `${server}/account/get-bucket-sheet-feeds`,
  fetchShadowSheetFeeds: `${server}/account/get-shadow-bucket-sheet-feeds`,

  updateTimelineMessage: `${server}/account/update-timeline-message`,
  deleteTimelineMessage: `${server}/account/delete-timeline-message`,

  notifications: `${server}/account/notifications`,
  notificationRead: `${server}/account/notification-read`,
  notificationMarkAllRead: `${server}/account/notification-mark-all-read`,

  getPublishedThreadForRequest: `${server}/account/bucket-published-threads`,
  getShadowPublishedThreadForRequest: `${server}/account/shadow-bucket-published-threads`,

  createChartSheet: `${server}/account/create-chart-sheet`,
  createShadowChartSheet: `${server}/account/create-shadow-chart-sheet`,
  getChartSheets: `${server}/account/get-chart-sheets`,
  getShadowChartSheets: `${server}/account/get-shadow-chart-sheets`,

  getChartSideNotes: `${server}/account/get-chart-sidenotes`,
  getShadowChartSideNotes: `${server}/account/get-shadow-chart-sidenotes`,

  deleteChartSideNote: `${server}/account/delete-chart-sidenote`,
  deleteShadowChartSideNote: `${server}/account/delete-shadow-chart-sidenote`,
  updateSidenote: `${server}/account/update-chart-sidenote`,
  updateShadowSidenote: `${server}/account/update-shadow-chart-sidenote`,

  getPatientThreads: `${server}/account/get-submitted-threads`,
  getShadowPatientThreads: `${server}/account/get-shadow-submitted-threads`,

  getThreadSummary: `${server}/account/get-thread-summary`,
  getShadowThreadSummary: `${server}/account/get-shadow-thread-summary`,
  getPatientThreadSubmissions: `${server}/account/get-thread-submissions`,
  getShadowPatientThreadSubmissions: `${server}/account/get-shadow-thread-submissions`,

  getPollStudy: `${server}/account/get-poll-study`,
  getPollStudyForShadowBucket: `${server}/account/get-poll-study-for-shadow-bucket`,

  getAllBucketPollStudies: `${server}/account/get-all-bucket-poll-studies`,
  getAllShadowBucketPollStudies: `${server}/account/get-all-shadow-bucket-poll-studies`,

  createBucketPollStudy: `${server}/account/create-bucket-poll-study`,
  createShadowBucketPollStudy: `${server}/account/create-shadow-bucket-poll-study`,

  deleteBucketPollStudy: `${server}/account/delete-bucket-poll-study`,
  deleteShadowBucketPollStudy: `${server}/account/delete-shadow-bucket-poll-study`,

  getBucketPollStudy: `${server}/account/get-bucket-poll-study`,
  getShadowBucketPollStudy: `${server}/account/get-shadow-bucket-poll-study`,

  getBucketToBeSentChartsForPollStudy: `${server}/account/get-bucket-poll-study-tobe-sent-charts`,
  getShadowBucketToBeSentChartsForPollStudy: `${server}/account/get-shadow-bucket-poll-study-tobe-sent-charts`,

  getBucketPendingPollStudyCharts: `${server}/account/get-bucket-poll-study-pending-charts`,
  getShadowBucketPendingPollStudyCharts: `${server}/account/get-shadow-bucket-poll-study-pending-charts`,

  getBucketAnsweredPollStudyCharts: `${server}/account/get-bucket-poll-study-answered-charts`,
  getShadowBucketAnsweredPollStudyCharts: `${server}/account/get-shadow-bucket-poll-study-answered-charts`,

  updateBucketPollStudy: `${server}/account/update-bucket-poll-study`,
  updateShadowBucketPollStudy: `${server}/account/update-shadow-bucket-poll-study`,

  sendBucketPollStudyToTargetedCharts: `${server}/account/start-bucket-poll-study-for-targeted-charts`,
  sendShadowBucketPollStudyToTargetedCharts: `${server}/account/start-shadow-bucket-poll-study-for-targeted-charts`,

  sendBucketPollStudyToSelectedCharts: `${server}/account/start-bucket-poll-study-for-selected-charts`,
  sendShadowBucketPollStudyToSelectedCharts: `${server}/account/start-shadow-bucket-poll-study-for-selected-charts`,

  sendAnnoncementToPendingChartsInPollStudy: `${server}/account/send-announcement-to-pending-charts-of-poll-study`,
  sendShadowAnnoncementToPendingChartsInPollStudy: `${server}/account/send-shadow-bucket-announcement-to-pending-charts-of-poll-study`,

  resendPollToPendingCharts: `${server}/account/resend-poll-study-to-pending-charts`,
  resendShadowPollToPendingCharts: `${server}/account/resend-shadow-poll-study-to-pending-charts`,

  removeChartFromBucketPoll: `${server}/account/remove-chart-from-bucket-poll`,
  removeChartFromShadowBucketPoll: `${server}/account/remove-chart-from-shadow-bucket-poll`,
  ignoreChartFromBucketPoll: `${server}/account/ignore-chart-from-bucket-poll`,
  ignoreChartFromShadowBucketPoll: `${server}/account/ignore-chart-from-shadow-bucket-poll`,

  sendAnnoncementToAnsweredCharts: `${server}/account/send-announcement-to-answered-charts-of-poll-study`,
  sendAnnoncementToAnsweredChartsShadow: `${server}/account/send-shadow-bucket-announcement-to-answered-charts-of-poll-study`,

  resetBucketPollStudy: `${server}/account/reset-bucket-poll-study`,
  resetShadowBucketPollStudy: `${server}/account/reset-shadow-bucket-poll-study`,

  saveBucketRegPage: `${server}/account/save-bucket-registration-page`,
  saveShadowBucketRegPage: `${server}/account/save-shadow-bucket-registration-page`,

  createBucketGoLive: `${server}/account/create-bucket-golive`,
  getBucketGoLiveList: `${server}/account/get-bucket-go-lives-list`,
  getBucketGoLive: `${server}/account/get-bucket-go-live`,
  updateBucketGoLiveFilters: `${server}/account/update-bucket-go-live-filter`,
  updateBucketGoLiveSettings: `${server}/account/update-bucket-go-live-settings`,
  getGoLiveAudience: `${server}/account/get-go-live-audience`,

  getGoLiveSessions: `${server}/account/get-bucket-go-live-sessions`,
  createGoLiveSession: `${server}/account/create-bucket-go-live-session`,
  deleteGoLiveSession: `${server}/account/delete-bucket-go-live-session`,
  getGoLiveSession: `${server}/account/get-bucket-go-live-session`,
  getGoLiveSessionChats: `${server}/account/get-bucket-go-live-session-chats`,
  updateGoLiveSessionOffer: `${server}/account/update-go-live-session-offer`,
  removeGoLiveSessionOffer: `${server}/account/remove-go-live-session-offer`,
  stopBucketGoLiveSession: `${server}/account/stop-bucket-go-live-session`,
  updateGoLiveSessionLiveURL: `${server}/account/update-golive-session-stream-url`,
  updateGoLiveActiveSession: `${server}/account/update-golive-active-session`,
  updateGoLiveSessionCalendlyLink: `${server}/account/update-go-live-session-calendly-link`,
  removeGoLiveSessionCalendlyLink: `${server}/account/remove-go-live-session-calendly-link`,

  createBucketDummyPatient: `${server}/account/create-bucket-dummy-patient`,
  createShadowBucketDummyPatient: `${server}/account/create-shadow-bucket-dummy-patient`,
  getFakerDummyPatient: `${server}/account/get-faker-dummy-patient`,

  shareChartWithShadow: `${server}/account/share-chart-with-shadow`,
  removeShareChartWithShadow: `${server}/account/remove-share-chart-with-shadow`,

  getBucketSaleReciepts: `${server}/account/get-bucket-sale-reciepts`,
  getBucketSubscriptions: `${server}/account/get-bucket-subscriptions`,
  getBucketScheduledPayments: `${server}/account/get-bucket-scheduled-payments`,

  deleteBucketProduct: `${server}/account/delete-bucket-product`,
  deleteBucketProductGroup: `${server}/account/delete-bucket-product-group`,

  createChartsAnnouncement: `${server}/account/create-charts-announcement`,
  getChartsAnnouncements: `${server}/account/get-charts-announcements`,
  deleteChartAnnouncement: `${server}/account/delete-chart-announcement`,

  updateSDOfGoLiveSession: `${server}/account/update-scheduled-description-golive-session`,
  updateGoLivePixelSettings: `${server}/account/update-golive-pixel-settings`,

  getTemplates: `${server}/account/get-templates`,
  createTemplate: `${server}/account/create-template`,
  getTemplate: `${server}/account/get-template`,
  updateTemplate: `${server}/account/update-template`,
  deleteTemplate: `${server}/account/delete-template`,

  getTemplateEmailData: `${server}/account/get-template-email-data`,
  updateTemplateEmailData: `${server}/account/update-template-email-data`,
  emailAssetUpdload: `${server}/account/email-asset-upload`,
  sendTestEmail: `${server}/account/send-test-email`,

  getGoLiveFutureTasks: `${server}/account/get-golive-future-tasks`,
  createGoLiveFutureTask: `${server}/account/create-golive-future-task`,

  updateGoLiveShadows: `${server}/account/update-golive-shadows`,
  getShadowGoLiveLinks: `${server}/account/get-shadow-golive-links`,

  getGoLiveLeadsList: `${server}/account/get-golive-leads-list`,
  getBucketOldLeads: `${server}/account/get-bucket-old-leads`,
  sendTextMessageToOldLeads: `${server}/account/send-message-to-old-leads`,

  createBucketMonitor: `${server}/account/create-bucket-monitor`,
  getBucketMonitors: `${server}/account/get-bucket-monitors`,
  getBucketMonitorDetails: `${server}/account/get-bucket-monitor-details`,
  getBucketMonitorEvents: `${server}/account/get-bucket-monitor-events`,
  deleteMonitor: `${server}/account/delete-monitor`,

  getBackgroundTasks: `${server}/account/get-background-tasks`,
  getBackgroundTaskDetails: `${server}/account/get-background-task-details`,
  sendMagicLink: `${server}/account/send-onelink-to-patient`,
  archivePatient: `${server}/account/archive-patient`,
  deletePatient: `${server}/account/remove-patient-from-system`,
  getChartGoLiveMessages: `${server}/account/get-chart-golive-chats`,

  getTrackingEntities: `${server}/account/get-bucket-tracking-entities`,
  deleteTrackingEntities: `${server}/account/delete-bucket-tracking-entity`,
  createTrackingEntities: `${server}/account/create-bucket-tracking-entities`,
  getTrackingEntity: `${server}/account/get-bucket-tracking-entity`,

  getTrackingEntityLogs: `${server}/account/get-tracking-entity-logs`,

  sendTestCommunications: `${server}/account/send-test-communication`,
  sendCommunications: `${server}/account/send-communication`,
  addTrackerToGreeting: `${server}/account/add-tracker-to-greeting`,

  createInterimChart: `${server}/account/create-interim-chart`,
  getInterimCharts: `${server}/account/get-interim-charts`,
  fetchInterimChart: `${server}/account/fetch-interim-chart`,
  fetchInterimChartFeeds: `${server}/account/fetch-interim-chart-feeds`,
  fetchInterimChartSideNotes: `${server}/account/fetch-interim-chart-sidenotes`,

  awAnnouncements: `${server}/account/aw-announcements`,
  drip: `${server}/account/drips`,
  actionbutton: `${server}/account/action-button`,
  scheduled: `${server}/account/scheduled`,
  reminders: `${server}/account/reminders`,

  // Public Pages
  createPublicPage: `${server}/account/bucket-public-pages/create`,
  getPublicPages: `${server}/account/bucket-public-pages/get-list`,
  getPublicPage: `${server}/account/bucket-public-pages/get-page`,
  updatePublicPage: `${server}/account/bucket-public-pages/update`,
  deletePublicPage: `${server}/account/bucket-public-pages/delete`,
  getPageAnalytics: `${server}/account/bucket-public-pages/analytics`,

  getAIMessage: `${server}/account/ai/ai-message`,

  axios: {
    headers: {
      authorization: localStorage.getItem('access-token')
    }
  },

  PatientPortal: local ? 'http://localhost:3001' : process.env.REACT_APP_PORTAL_LINK,
  DoctorCockpit: local ? 'http://localhost:3000' : process.env.REACT_APP_ACCOUNT_LINK
}

export default APIConfig
