import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NotificationState {
  isNewNotificationAvailable: boolean
  NewNotificationCount: number
}

const initialState: NotificationState = {
  isNewNotificationAvailable: false,
  NewNotificationCount: 0
}

const AudioCallControl = createSlice({
  name: 'NotificationsData',
  initialState,
  reducers: {
    setIsNewNotificationAvailable(state, action: PayloadAction<{ value: boolean }>) {
      state.isNewNotificationAvailable = action.payload.value
    },

    setNotificationCount: (state, action: PayloadAction<{ count: number }>) => {
      state.NewNotificationCount = action.payload.count
    },

    incrementNotificationCount: (state) => {
      state.NewNotificationCount += 1
    }
  }
})

export const { setIsNewNotificationAvailable, setNotificationCount, incrementNotificationCount } = AudioCallControl.actions
export default AudioCallControl.reducer
