/* eslint-disable no-alert */
export default function LazyLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            const message = window.confirm('There is a new version available! Please reload.')
            if (message) {
              window.location.reload()
              return
            }
            reject(error)
            return
          }
          LazyLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 200)
      })
  })
}
